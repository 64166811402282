<template>
  <div v-if="!overlay">
    <!-- MAIN div contenitore da inserire nelle pagine Risultato di ricerca (class="main results-page") e Dettaglio volume (class="main detail") -->
    <div class="main detail" v-if="item">
      <!-- SEARCH -->
      <div class="search container bg-grey" style="z-index: 3" v-show="!showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div class="search__caption__copy__searchbox">
              <div class="col70 floatL filter__search">
                <v-select class="filter__search__select" name @change="filtering()" v-model="typeResearch" :value="typeResearch" :items="filtersTypeResearch">
                  <!--<option>Tutto il Catalogo</option>
                    <option v-if="checkRules()">I miei volumi</option>
                    <option>Unlimited</option>-->
                </v-select>
                <v-text-field
                  class="srch filter__search__input"
                  style="padding-top: 3px !important"
                  v-on:keyup="keyPress"
                  dense
                  v-model="searchText"
                  :placeholder="placeholder"
                  @click:append="
                    page = 1
                    research(0, 8, 'Tutto il Catalogo', true)
                  "
                  rounded
                  append-icon="search"></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search container bg-grey action-viewer" style="z-index: 10" v-show="showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div class="filter results__actions">
              <span class="lnk blue" style="cursor: pointer" @click="showViewer = false" title="Torna ai risultati di ricerca">
                <span class="mdi mdi-chevron-left"></span>
                <span class="text" style="font-weight: 700 !important">Torna al dettaglio del volume</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <v-overlay :value="loading_viewer">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-if="showViewer" class="reset-padding expandContainer" style="position: relative">
        <Viewer :key="currentKey" :settings="settings" :specialRole="specialRole"></Viewer>
      </v-container>
      <v-container v-if="item && !showViewer" class="reset-padding">
        <div class="section">
          <!-- RESULTS ACTIONS div in cui inserire le CTA torna alla ricerca e switch -->
          <div class="filter results__actions">
            <span v-show="$route.query.fromSearch || $route.query.fromResults" class="lnk blue" style="cursor: pointer" @click="back()" title="Torna ai risultati di ricerca">
              <span class="mdi mdi-chevron-left"></span>
              <span class="text" style="font-weight: 700 !important">Torna ai risultati di ricerca</span>
            </span>
            <div class="switch">
              <v-switch
                v-show="showFavBtn"
                dense
                v-model="item.provvedimento.utente.preferito"
                color="green"
                :label="`PREFERITI`"
                style="font-size: 11pt"
                @change="toggleFav(item)"></v-switch>
            </div>
          </div>

          <!-- DETAIL BOOK -->
          <div class="results-list">
            <div class="results-list__items">
              <div class="results-list__items__item clearfix">
                <div class="col40 floatL element__image">
                  <span class="element__image__action">
                    <div class="grid__item__caption__corner" v-if="item.provvedimento.utente.demo">DEMO</div>

                    <v-img
                      height="400"
                      width="280"
                      contain
                      style="cursor: pointer"
                      alt="Copertina volume"
                      @click="goToFirstSummary()"
                      v-if="item.provvedimento && item.provvedimento.campo_calcolato.cover_url"
                      :src="fixCopertine(
                        settings.copertina +
                        '/pdf/' +
                        item.provvedimento.id_doc_master
                          .toString()
                          .substring(item.provvedimento.id_doc_master.toString().length - 3, item.provvedimento.id_doc_master.toString().length) +
                        item.provvedimento.campo_calcolato.cover_url.toString() +
                        '?width=280')
                      "></v-img>

                    <v-img
                      :width="settings.homepage.image.width"
                      height="400"
                      contain
                      style="cursor: pointer"
                      @click="goToFirstSummary()"
                      v-else
                      alt="Copertina volume"
                      :src="settings.configuration.placeholderIMG"></v-img>
                  </span>
                </div>
                <div class="col60 floatR element__caption" style="text-align: left; padding-left: 30px">
                  <div class="element__caption__header">
                    <h3 class="title uppercase">{{ item.provvedimento.titolo.toUpperCase() }}</h3>
                    <p v-if="item.provvedimento.crm_product">{{ item.provvedimento.crm_product[0].sottotitolo }}</p>
                    <!-- checkEstrattoRule() != 'unlimited' || -->
                    <p v-if="item.provvedimento.utente && item.provvedimento.utente.volume == false && !item.provvedimento.is_unlimited">estratto del volume</p>
                  </div>
                  <div class="element__caption__metadata">
                    <div class="metadata-row">
                      <span class="metadata" v-if="item.autori && item.autori.length > 0">Autori:</span>
                      <span class="metadata__value" v-if="item.autori && item.autori.length <= 10">
                        <span v-for="(autore, index) in item.autori" :key="autore">
                          {{ autore.nome }} {{ autore.cognome }}
                          <span v-if="item.autori.length > 1 && index < item.autori.length - 1">;</span>
                        </span>
                      </span>
                      <span class="metadata__value" v-else-if="item.autori">
                        <span v-for="(autore, index) in item.autori.slice(0, 10)" :key="autore">
                          {{ autore.nome }} {{ autore.cognome }}
                          <span v-if="item.autori.length > 1 && index < item.autori.length - 1">;</span>
                        </span>
                        <a class="metadata__lnkmore" @click="showMoreAuthors = true" v-show="!showMoreAuthors">Leggi di più</a>
                        <span v-show="showMoreAuthors" v-for="(autore, index) in item.autori.slice(10, 100)" :key="autore">
                          {{ autore.nome }} {{ autore.cognome }}
                          <span v-if="item.autori.length > 1 && index < item.autori.slice(10, 100).length - 1">;</span>
                        </span>
                      </span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">Anno di edizione:</span>
                      <span class="metadata__value">{{ item.provvedimento.anno_ed }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata" v-show="false">Pagine volume:</span>
                      <span v-show="false" class="metadata__value" v-if="item.provvedimento.crm_product">{{ item.provvedimento.crm_product[0].ce_numero_pag_romane }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">Cod. Volume:</span>
                      <span class="metadata__value">{{ item.provvedimento.cod_vol }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">Collana:</span>
                      <span class="metadata__value">{{ item.provvedimento.collana }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">ISBN:</span>
                      <span class="metadata__value">{{ item.provvedimento.cod_isbn }}</span>
                    </div>
                    <div class="metadata-row">
                      <span class="metadata">Materie:</span>
                      <span class="metadata__value" v-if="item.provvedimento.materie.length > 0">
                        <span v-for="mat in item.provvedimento.materie" :key="mat">
                          {{ mat.desc }}
                          <span v-if="item.provvedimento.materie.length > 1">;</span>
                        </span>
                      </span>
                    </div>
                    <div class="metadata-row mt-2" v-if="specialRole">
                      <v-btn class="primary" small style="background: #25427c !important" @click="dialogDownload = true">Scarica il volume in PDF</v-btn>
                    </div>
                    <div
                      class="element__caption__add"
                      @click="goToShop(item)"
                      style="display: table; margin-bottom: 5px; min-width: 220px; cursor: pointer; line-height: 25px !important; letter-spacing: 0.6px"
                      v-if="item.provvedimento.utente.volume == false">
                      <span class="label" style="line-height: 14px; font-size: 8.5pt; text-transform: initial">
                        Acquista il Volume nella<br />
                        versione cartacea dallo shop GFL
                      </span>
                      <a target="_blank" v-show="false" :href="'https://shop.giuffre.it/product.php?cod=' + item.provvedimento.codVol" class="metadata" style="color: #0095d8"
                        >Acquista sullo shop</a
                      >
                    </div>
                    <!--<div class="metadata-row">
                      <a target="_blank" :href="'https://shop.giuffre.it/product.php?cod=' + item.provvedimento.cod_vol" class="metadata" style="color:#0095d8">Acquista sullo shop</a>
                    </div>-->
                  </div>

                  <div class="element__caption__add" v-show="showUnlimitedBtn">
                    <span class="label">
                      Incluso nell'abbonamento
                      <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                    </span>
                    <a
                      class="lnk lightBlue"
                      @click="
                        loadingAdd = true
                        addToUnlimited(item)
                      "
                      v-show="showUnlimitedBtn"
                      title="Aggiungi subito nella sezione Unlimited">
                      <span class="mdi mdi-plus-circle"></span>
                      <span class="text">Aggiungi subito</span>
                    </a>
                  </div>

                  <div class="element__caption__add" v-show="showUnlimitedBtnFake" @click="goToUnlimited" style="cursor: pointer">
                    <span class="label">
                      Incluso nell'abbonamento
                      <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                      <br />
                      <a target="_blank" @click="goToUnlimited" class="metadata" style="color: #0095d8; display: table-cell; padding-top: 10px !important">Scopri di più</a>
                    </span>
                  </div>

                  <div class="element__caption__add" v-show="showUnlimitedBtnDelete">
                    <span class="label">
                      Incluso nell'abbonamento
                      <span @click="goToUnlimited" style="cursor: pointer" class="title uppercase">Unlimited</span>
                    </span>
                    <a
                      class="lnk red"
                      @click="
                        loadingDelete = true
                        removeFromUnlimited(item)
                      "
                      v-show="showUnlimitedBtnDelete"
                      title="Rimuovi dalla sezione Unlimited">
                      <span class="mdi mdi-close-circle"></span>
                      <span class="text">Rimuovi</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RESULT LIST -->
          <div class="other-results" v-if="chapters && chapters.length > 0">
            <div class="other-results__actions">
              <!-- CTA Espandi/Restringi Al clic di Espandi deve essere mostrato result-list__box e al click di Restringi deve esserfe nascosto result-list__box -->
              <a @click="expand = !expand" title="Mostra l'elenco dei risultati di ricerca" class="lnk arrow-right blue" v-show="!expand">
                <span class="text" style="font-weight: 700 !important">Espandi tutti i risultati</span>
                <span class="mdi mdi-chevron-down"></span>
              </a>
              <a @click="expand = !expand" title="Nascondi l'elenco dei risultati di ricerca" class="lnk arrow-right blue" v-show="expand">
                <span class="text" style="font-weight: 700 !important">Restringi tutti i risultati</span>
                <span class="mdi mdi-chevron-up"></span>
              </a>
            </div>
            <!-- box da mostrare/nascondere -->
            <div class="other-results__box" style="text-align: left" v-show="expand">
              <div class="other-results__header"></div>
              <div class="other-results__items">
                <div class="other-results__items__item" v-for="chapter in chapters" :key="chapter">
                  <v-list-item-content style="text-align: left">
                    <v-list-item-title v-if="chapter._source" class="title-chapter">
                      <h6
                        class="title-chapter-h6"
                        v-if="chapter.highlight && chapter.highlight['documento.descrizione']"
                        v-html="chapter.highlight['documento.descrizione'][0]"
                        @click="openViewer(chapter)"></h6>
                      <h6 class="title-chapter-h6" v-else v-html="chapter._source.documento.descrizione" @click="openViewer(chapter)"></h6>
                    </v-list-item-title>
                    <span class="metadata">
                      Pagine capitolo: {{ chapter._source.documento.pagine }}
                      <abbr v-if="chapter.pdf_partial_view" style="color: black">(Visualizzazione limitata al 10% delle pagine del capitolo)</abbr>
                    </span>
                    <div v-if="chapter.highlight && chapter.highlight['testo.italian_stemmer']" class="lnk" style="font-size: 1em; line-height: 20px" @click="openViewer(chapter)">
                      <span v-for="text in chapter.highlight['testo.italian_stemmer']" :key="text">
                        <span v-html="text"></span>
                      </span>
                    </div>
                  </v-list-item-content>
                </div>
              </div>
            </div>
          </div>

          <v-card color="basil" class="book-tabs" style="padding-left: 10px">
            <v-tabs v-model="tab" background-color="transparent" color="basil">
              <v-tab v-for="item in tabss" :key="item" class="v-item-group v-slide-group v-tabs-bar grey--text accent-4" style="font-weight: 500; letter-spacing: 1px">{{
                item.title
              }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card color="basil" flat>
                  <v-card-text style="text-align: left">
                    <ul class="lnk-list">
                      <li v-for="(summary, i) in item.documenti" :key="i" @click="openViewer(summary)">
                        <a style="text-decoration: underline" v-html="summary.campo_calcolato.estremo" :title="summary.campo_calcolato.estremo"></a>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil" flat>
                  <v-card-text v-if="item.provvedimento.crm_product.length > 0" style="text-align: left">
                    <!-- ABSTRACT -->
                    <p v-html="item.provvedimento.crm_product[0].ce_abstract"></p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!--<v-tab-item :transition="'fade'" :reverse-transition="'fade'">
                <v-card color="basil" flat>
                  <v-card-text style="text-align:left">
                    <div class="book-tabs__authors">
                      <div
                        class="book-tabs__authors__item"
                        v-for="autore in item.autori"
                        :key="autore"
                      >
                        <h6>{{autore.nome}} {{autore.cognome}}</h6>
                        <p>{{autore.testo}}</p>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>-->
            </v-tabs-items>
          </v-card>
        </div>
        <div class="section" v-if="catalogue">
          <div class="intro">
            <span class="border-lightBlue" v-if="chapters && chapters.length > 0"></span>
            <h2>Ti potrebbero interessare anche</h2>
          </div>

          <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
          <div class="grid clearfix" v-if="catalogue">
            <v-container style="margin-top: -20px" class="hidden-md-and-up">
              <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="height: 306px; !important; width: 217px; !important">
                <v-carousel v-model="model" hide-delimiters @change="carousel(item)">
                  <v-carousel-item v-for="item in catalogue" :key="item" @click="detail(item)">
                    <v-img
                      v-if="item._source.provvedimento.campiCalcolati.cover_url"
                      height="306"
                      width="217"
                      :src="fixCopertine(
                        settings.copertina +
                        '/pdf/' +
                        item._source.provvedimento.idDocMaster
                          .toString()
                          .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                        '/' +
                        item._source.provvedimento.campiCalcolati.cover_url +
                        '?width=217&fit=outside')
                      "></v-img>

                    <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
              <v-row justify="space-around" v-if="currentItem" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
                <div class="grid__item__caption__text">
                  <h4>{{ currentItem._source.provvedimento.titolo }}</h4>
                  <p>
                    <span v-if="currentItem._source.provvedimento.collana">{{ currentItem._source.provvedimento.collana.descrizione }}</span>
                    <br />
                    <span style="font-weight: bold" v-if="currentItem._source.autori && currentItem._source.autori[0]"
                      >{{ currentItem._source.autori[0].nome.substring(0, 1) }}. {{ currentItem._source.autori[0].cognome }}</span
                    >
                    <span style="font-weight: bold" v-if="currentItem._source.autori && currentItem._source.autori[1]"
                      >{{ currentItem._source.autori[1].nome.substring(0, 1) }}. {{ currentItem._source.autori[1].cognome }}</span
                    >
                  </p>
                </div>
              </v-row>
            </v-container>
            <v-container class="hidden-sm-and-down">
              <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
              <div class="grid clearfix">
                <div class="grid__item" v-for="item in catalogue" :key="item" @click="detail(item)">
                  <a class="grid__item__caption">
                    <div class="grid__item__caption__image">
                      <v-img
                        v-if="item._source.provvedimento.campiCalcolati.cover_url"
                        height="306"
                        width="217"
                        :src="fixCopertine(
                          settings.copertina +
                          '/pdf/' +
                          item._source.provvedimento.idDocMaster
                            .toString()
                            .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                          '/' +
                          item._source.provvedimento.campiCalcolati.cover_url +
                          '?width=217&fit=outside')
                        "></v-img>

                      <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                    </div>
                    <div class="grid__item__caption__text">
                      <h4>{{ item._source.provvedimento.titolo }}</h4>
                      <p>
                        <span v-if="item._source.provvedimento.collana">{{ item._source.provvedimento.collana.descrizione }}</span>
                        <br />
                        <span style="font-weight: bold" v-if="item._source.autori && item._source.autori[0]"
                          >{{ item._source.autori[0].nome.substring(0, 1) }}. {{ item._source.autori[0].cognome }}</span
                        >
                        <span style="font-weight: bold" v-if="item._source.autori && item._source.autori[1]"
                          >{{ item._source.autori[1].nome.substring(0, 1) }}. {{ item._source.autori[1].cognome }}</span
                        >
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <!---->
            </v-container>
          </div>
        </div>
      </v-container>
    </div>
    <div v-else>
      <v-progress-circular style="margin-top: 20px; margin-bottom: 20px" indeterminate color="primary"></v-progress-circular>
    </div>
    <DownloadPdf v-if="dialogDownload" @trigger="triggerVisibility" />
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import axios from 'axios'
  import configuration from '../configuration_variables.json'
  import viewer from '../components/Viewer'
  import DownloadPdf from '../components/DownloadPDF.vue'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      Viewer: viewer,
      DownloadPdf: DownloadPdf
    },
    data: (vm) => ({
      item: null,
      previews: [],
      catalogue: null,
      loading_viewer: false,
      showFavBtn: false,
      showUnlimitedBtn: false,
      is_in_preferito: true,
      showModalError: false,
      showUnlimitedBtnFake: false,
      tok: null,
      currentKey: 'viewer',
      showUnlimitedBtnDelete: false,
      chapters: [],
      loading: true,
      showMoreAuthors: false,
      showTenPercent: false,
      loadingAdd: false,
      expand: true,
      loadingDelete: false,
      stopLoad: false,
      showViewer: false,
      hasEntities: false,
      filtersTypeResearch: ['Tutto il Catalogo', 'I miei volumi', 'Unlimited'],
      tab: null,
      placeholder: 'Cerca in tutto il Catalogo GFL',
      currentItem: null,
      tabss: [
        {
          title: 'INDICE',
          text: 'aa'
        },
        {
          title: 'ABSTRACT',
          text: 'bb'
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      typeResearch: 'Tutto il Catalogo',
      list: [],
      showResAdv: false,
      materiaSelected: {
        nome_interesse: 'Tutto il Catalogo'
      },
      nlp: null,
      oneTime: true,
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ],
      dialogDownload: false
    }),
    watch: {
      $route(to, from) {
        if (this.settings.sso && this.$route.query.cod_vol) {
          this.item = null
          this.info(this.$route.query.cod_vol)
          this.$forceUpdate()
          window.scroll(0, 0)
          //
          this.$gtm.trackEvent({
            event: 'sezione',
            azione: 'open',
            descrizione: 'volume',
            proprieta: this.$route.query.cod_vol,
            utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
            crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
            stato: ''
          })
        }
      },
      typeResearch(val, old) {
        if (val == 'Tutto il Catalogo') this.placeholder = 'Cerca in tutto il Catalogo GFL'
        if (val == 'I miei volumi') this.placeholder = 'Cerca ne I miei volumi'
        if (val == 'Unlimited') this.placeholder = 'Cerca in Unlimited'
      }
    },
    computed: {
      ...mapState(['config']),
      ...mapGetters(['downloadRole']),
      specialRole() {
        return this.item.provvedimento.is_downloadable && this.downloadRole
      }
    },
    metaInfo() {
      if (this.item && this.item.provvedimento) {
        var materie = []
        if (this.item.provvedimento.materie) {
          this.item.provvedimento.materie.forEach((element) => {
            materie.push(element.desc)
          })
        }

        var autori = []
        if (this.item.autori) {
          this.item.autori.forEach((element) => {
            autori.push(element.cognome)
          })
        }

        return {
          title: this.item.provvedimento.titolo,
          meta: [
            {
              name: 'description',
              content: this.item.provvedimento.crm_product[0].ce_abstract
            },
            {
              name: 'keywords',
              content: `${this.item.provvedimento.titolo},${materie.length > 0 ? materie.join(',') : ''},
            ${this.item.provvedimento.cod_vol}, ${this.item.provvedimento.cod_isbn}, ${autori.length > 0 ? autori.join(',') : ''}`
            },
            {
              property: 'og:title',
              content: this.item.provvedimento.titolo
            },
            {
              property: 'og:url',
              content: location.href
            },
            {
              property: 'og:description',
              content: this.item.provvedimento.crm_product[0].ce_abstract
            },
            { property: 'og:type', content: 'product' },
            {
              property: 'og:image',
              content: this.item.provvedimento.campo_calcolato.cover_url
                ? this.settings.copertina +
                  '/pdf/' +
                  this.item.provvedimento.id_doc_master
                    .toString()
                    .substring(this.item.provvedimento.id_doc_master.toString().length - 3, this.item.provvedimento.id_doc_master.toString().length) +
                  this.item.provvedimento.campo_calcolato.cover_url.toString() +
                  '?width=280'
                : ''
            },
            { name: 'robots', content: 'INDEX,FOLLOW' }
          ]
        }
      }
    },
    async mounted() {
      var self = this
      window.onmessage = function (e) {
        if (e.data == 'locked') {
          self.showTenPercent = true
          self.$forceUpdate()
        }
        if (e.data == 'unlocked') {
          self.showTenPercent = false
          self.$forceUpdate()
        }
      }

      this.$emit('checkQSTicket', this.$route.query)

      if (true || this.$el._prevClass) {
        this.loading = true

        if (this.authorization.jwt) {
          this.user = this.authorization
        }
        if (this.settings.configuration) {
          if (true || this.$el._prevClass) {
            this.$gtm.trackEvent({
              event: 'sezione',
              azione: 'open',
              descrizione: 'volume',
              proprieta: this.$route.query.cod_vol,
              utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
              crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
              stato: ''
            })
          }
        }
        this.checkRules()
        if (this.settings.sso && this.$route.query.cod_vol && !this.catalogue) {
          await this.info(this.$route.query.cod_vol)
          if (this.$route.query.fromSearch) {
            //verifico le entità
            this.searchText = this.$route.query.fromSearch
            this.getEntities(this.$route.query.entities)
          }
          if (this.$route.query.from) {
            //verifico le entità
            this.typeResearch = decodeURIComponent(this.$route.query.from)
          }
        }
      }
    },
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this

        this.loading = true
        if (method == 'GET') {
          await axios
            .get(call, { headers: header })
            .then(async (response) => {
              switch (type) {
                case 'list':
                  self.catalogue = response.data.es.list
                  console.log(self.catalogue)
                  if (self.catalogue && self.catalogue.length > 0) {
                  } else {
                    self.stopLoad = true
                  }
                  //self.$forceUpdate()
                  break
                case 'detail':
                  self.item = response.data
                  self.$options.computed.$metaInfo()

                  var header = {
                    Authorization: self.tok
                  }

                  //richiamo metodo pdf previews
                  if (self.item.provvedimento.campo_calcolato.cover_url) {
                    //self.loadPreviews();
                  }
                  if (!self.item.documenti) {
                    //non sono loggato, recupero il sommario a parte
                    //nascondo certi elementi
                    self.tok = self.settings.free.jwt
                    self.showFavBtn = false
                    self.showUnlimitedBtn = false
                    self.showUnlimitedBtnDelete = false
                    self.showUnlimitedBtnFake = false
                    self.recoverSummary(self.item.provvedimento.id_doc_master)
                  } else {
                    //is_unlimited vuol dire se ha il volume comprato unlimited
                    if (self.settings.freeToken) {
                      if (self.item.provvedimento.is_unlimited == true) {
                        self.showUnlimitedBtnFake = true
                      } else self.showUnlimitedBtnFake = false
                    } else {
                      //logica showFav
                      var role36 = self.authorization.profile.roles.find((x) => x.pos.toString() == '36')
                      var role37 = self.authorization.profile.roles.find((x) => x.pos.toString() == '37')
                      var roleUnl = self.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')

                      if (self.item.provvedimento.is_unlimited == true && self.checkRulesNotUnlimited()) {
                        self.showUnlimitedBtnFake = true
                      } else self.showUnlimitedBtnFake = false

                      if (
                        self.item.provvedimento.is_unlimited == true &&
                        self.item.provvedimento.utente.unlimited == false &&
                        roleUnl //c'era in or anche role36
                      ) {
                        self.showUnlimitedBtn = true
                      } else {
                        self.showUnlimitedBtn = false
                      }

                      if (
                        self.item.provvedimento.is_unlimited == true &&
                        self.item.provvedimento.utente.unlimited == true &&
                        roleUnl //c'era in or anche role36
                      ) {
                        self.showUnlimitedBtnDelete = true
                      } else {
                        self.showUnlimitedBtnDelete = false
                      }

                      if (self.item.provvedimento.is_unlimited == true && role37) {
                        self.showFavBtn = true
                      } else if (
                        self.item.provvedimento.utente.volume == true &&
                        (roleUnl || role37) //c'era in or anche role36
                      ) {
                        self.showFavBtn = true
                      } else self.showFavBtn = false
                    }
                  }
                  if (!self.$route.query.fromSearch) {
                    //PBV-326
                    await this.REQUEST(
                      self.settings.configuration.apiURL + '/search/volumi/morelikethis/' + self.item.provvedimento.id_doc_master + '/' + 8,
                      self.tok ? header : null,
                      'GET',
                      'list',
                      null
                    )
                  } else {
                    var body = ''
                    if (self.item.provvedimento.materie && self.item.provvedimento.materie.length > 0) {
                      var materie = []
                      self.item.provvedimento.materie.forEach((element) => {
                        materie.push(element.id)
                      })
                      body = {
                        text: self.$route.query.fromSearch,
                        filters: [
                          {
                            name: 'codVol',
                            value: self.$route.query.cod_vol
                          },
                          {
                            name: 'materia',
                            value: materie
                          }
                        ]
                      }
                    } else {
                      body = {
                        text: self.$route.query.fromSearch,
                        filters: [
                          {
                            name: 'codVol',
                            value: self.$route.query.cod_vol
                          }
                        ]
                      }
                    }
                    await this.REQUEST(
                      self.settings.configuration.apiURL + '/search/volumi/morelikethis/' + self.item.provvedimento.id_doc_master + '/' + 8,
                      self.tok ? header : null,
                      'GET',
                      'list',
                      body
                    )
                  }
                  self.loading = false
                  break
                case 'recoverSummaryApart':
                  if (self.item) {
                    self.item.documenti = response.data.documenti
                  } else {
                    console.log('error summary')
                  }
                  break
              }
            })
            .catch(function (error) {
              console.log(error)
              switch (type) {
                case 'detail':
                  /*localStorage.token_biblio_vol = null;
                localStorage.token_biblio_vol_jwt = null;
                self.showFavBtn = false;
                self.showUnlimitedBtn = false;
                self.showModalError = true;*/
                  //self.info(self.$route.query.cod_vol);
                  console.log(error)
                  break
              }
            })
        } else if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'toggleFavorites':
                  break
                case 'chapters':
                  self.chapters = response.data.list
                  break
                case 'unlimited':
                  self.loadingAdd = false
                  self.showUnlimitedBtn = false
                  self.showUnlimitedBtnDelete = true
                  break
                case 'search':
                  if (self.settings.catalogo) {
                    self.settings.catalogo.search = response.data
                    self.settings.catalogo.typeResearch = self.typeResearch
                    self.settings.catalogo.fromDetail = true
                    self.settings.catalogo.filters = {
                      typeResearch: self.typeResearch
                    }
                  } else {
                    self.settings.catalogo = {
                      search: response.data,
                      materiaSelected: self.materiaSelected,
                      materie: self.materie,
                      typeResearch: self.typeResearch,
                      fromDetail: true,
                      filters: {
                        typeResearch: self.typeResearch
                      }
                    }
                  }

                  self.$router.push({
                    path: 'results',
                    query: { more: true }
                  })

                  break
              }
            })
            .catch(function (error) {
              console.log(error)

              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        } else {
          await axios
            .delete(call, { data: body, headers: header })
            .then(function (response) {
              switch (type) {
                case 'toggleFavorites':
                  break
                case 'unlimited':
                  self.loadingDelete = false
                  self.showUnlimitedBtn = true
                  self.showUnlimitedBtnDelete = false
                  break
              }
            })
            .catch(function (error) {
              console.log(error)

              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        }
      },
      back() {
        if (this.settings.catalogo && this.settings.catalogo.search && this.$route.query.fromSearch.length > 0) {
          this.settings.catalogo.comingBack = true

          this.$router.push({
            path: '/results'
          })
        } else {
          /*this.settings.catalogo = null;*/
          this.settings.comingBack = true
          this.$router.push({
            path: '/results?more=true' + (this.settings.catalogo && this.settings.catalogo.hadVolumes ? '&hadVolumes=true' : '')
          })
        }
      },
      filtering() {
        this.research()
      },
      goToUnlimited() {
        this.$router.push({ path: '/unlimited' })
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.$route.query.more = null
          this.page = 1
          this.removenlp = false
          this.research()
        }
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
            fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
      checkRulesNotUnlimited() {
        var role37 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
        var role36 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
        var unl = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
        if (role37 && !unl) return true //c'era in or anche !role36
        else return false
      },
      checkEstrattoRule() {
        if (this.user) {
          //se è loggato
          //verifico che non sia unlimited
          var unl = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
          var role37 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
          var role36 = this.user.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
          if (unl) return 'unlimited' //c'era in or anche !role36
          else {
            if (role37 || role36) return 'other'
            return 'none'
          }
        } else {
          return 'none'
        }
      },
      getEntities(val) {
        if (val && val == 'true') {
          this.hasEntities = true
        } else {
          this.hasEntities = false
          this.getChapters()
        }
      },
      carousel(item) {
        this.currentItem = this.catalogue[this.model]
      },
      addToUnlimited(item) {
        var header = {
          Authorization: this.tok
        }
        var body = {
          cod_vol: item.provvedimento.cod_vol
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'POST', 'unlimited', body)
      },
      checkRules() {
        if (this.user) {
          var role37 = this.user.profile.roles.find((x) => x.pos == 37)
          if (role37) this.filtersTypeResearch = ['Tutto il Catalogo', 'I miei volumi', 'Unlimited']
          else this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        } else {
          this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        }
      },
      removeFromUnlimited(item) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        var body = {
          cod_vol: item.provvedimento.cod_vol
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'DELETE', 'unlimited', body)
      },
      goToShop(item) {
        window.open('https://shop.giuffre.it/product.php?cod=' + item.provvedimento.cod_vol + '', '_blank')
      },
      toggleFav(item) {
        var header = {
          Authorization: this.tok
        }
        var body = {
          cod_vol: item.provvedimento.cod_vol
        }
        if (!item.provvedimento.utente.preferito) {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'DELETE', 'toggleFavorites', body)
        } else {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'POST', 'toggleFavorites', body)
        }
      },
      getChapters() {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        var body = {
          text: this.$route.query.fromSearch,
          sort: {
            name: '_score',
            order: 'desc'
          },
          filters: [
            {
              name: 'codVol',
              value: this.$route.query.cod_vol
            }
          ]
        }
        this.REQUEST(configuration.apiURL + '/search/volumi/capitoli/0/100', header, 'POST', 'chapters', body)
      },
      recoverSummary(id) {
        var header = {
          Authorization: this.tok
        }
        this.REQUEST(configuration.apiURL + '/volume/sommario/' + id, header, 'GET', 'recoverSummaryApart', null)
      },
      parseJwt(token) {
        //rivedi logica vecchia del jwt, mancano info
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )

        return JSON.parse(jsonPayload)
      },
      hasHistory() {
        return window.history?.length > 2
      },
      rndStr(len) {
        let text = ' '
        let chars = 'abcdefghijklmnopqrstuvwxyz'
        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
      },
      refreshComponent() {
        this.currentKey = this.rndStr(8)
        this.$forceUpdate()
      },
      openViewer(item, download) {
        this.loading_viewer = true

        var url = this.settings.pdfviewer ? this.settings.pdfviewer + 'newViewer.html' : this.settings.pdfviewer_express
        if (this.hasEntities) {
          url += '?token=' + item.v_token
        } else {
          if (this.$route.query.fromSearch) {
            url += '?search=' + this.$route.query.fromSearch + '&token=' + item.v_token
          } else {
            url += '?token=' + item.v_token
          }
        }
        //window.open(url, "_blank");
        this.settings.viewerUrl = url
        this.settings.download = download
        //BVA-88
        localStorage.currentPdfViewerURL = url
        this.showViewer = true
        window.scroll(0, 0)
        this.refreshComponent()
        setTimeout(() => {
          this.loading_viewer = false
        }, 3000)
      },
      goToFirstSummary(download) {
        this.openViewer(this.item.documenti[0], download)
      },
      loadPreviews() {
        var header = null
        var body = {
          document: link,
          zoomFactor: 1,
          isFileName: true,
          uniqueId: 'Sync_PdfViewer_test-7699de8d8646',
          action: 'Load',
          elementId: 'pdfViewer'
        }
        this.REQUEST(configuration.apiPDF, header, 'POST', 'preview', body)
      },
      async research() {
        var from = 0
        var body = {}
        if (this.searchText != null && this.searchText.length > 0) {
          if (this.materiaSelected && this.materiaSelected.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'materia', value: [this.materiaSelected.interesse_id] }],
              sort: { name: '_score', order: 'desc' }
            }
          } else {
            body = {
              filters: [],
              sort: { name: '_score', order: 'desc' }
            }
          }
          if (this.searchText) {
            body.text = this.searchText
          }
          if (this.typeResearch && this.typeResearch == 'Tutto il Catalogo') {
          }
          if (this.typeResearch && this.typeResearch == 'I miei volumi') {
            body.filters.push({ name: 'codiciCliente', value: true })
          }
          if (this.typeResearch && this.typeResearch == 'Unlimited') {
            body.filters.push({ name: 'volumiUnlimited', value: true })
          }
          var header = {
            Authorization: localStorage.token_biblio_vol_jwt
          }
          await this.REQUEST(this.settings.configuration.apiURL + '/search/volumi/' + from + '/8', header, 'POST', 'search', body)
        }
      },
      detail(item) {
        this.$router
          .push({
            path: '/details',
            query: { cod_vol: item._source.provvedimento.codVol }
          })
          .catch((err) => {})
        //location.reload();
      },
      async info(codice) {
        var tok = null
        if (this.authorization.jwt) {
          tok = this.authorization.jwt
        } else if (this.settings.freeToken) {
          tok = this.settings.free.jwt
        } else {
          tok = localStorage.token_biblio_vol_jwt
        }
        if (tok) {
          this.tok = tok
          var header = {
            Authorization: tok
          }
          await this.REQUEST(configuration.apiURL + '/volume/info/codice_volume/' + codice, header, 'GET', 'detail', null)
        }
      },
      triggerVisibility(val) {
        this.dialogDownload = val
      }
    }
  }
</script>
<style>
  em {
    /*background: gold !important;
  padding: 2px !important;*/
    color: #ba0000;
    font-size: inherit !important;
    font-weight: normal !important;
    font-style: initial !important;
  }

  .cardsVolumi .v-image__image.v-image__image--cover {
    background-position: top !important;
  }

  .v-input__slot {
    border: none;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .v-select__selections input,
  .srch input {
    background: transparent !important;
    border: none;
  }
  .wrap_floatL {
    text-align: right;
  }

  .element__caption__add {
    min-width: 220px !important;
  }

  .lnk:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    .wrap_floatL {
      text-align: center !important;
    }
    .srch {
      width: 100% !important;
      margin-left: -0px !important;
    }
  }
</style>
