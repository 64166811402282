<template>
  <div v-if="settings">
    <div class="search container-fluid bg-grey action-viewer" style="z-index: 9999; position: absolute; top: -10px; display: inline-table; left: 0px" v-show="settings.expand">
      <div class="search__caption">
        <div class="search__caption__copy">
          <!-- viene mostrato solo in versione smartphone -->
          <div class="filter results__actions">
            <span class="lnk blue" style="cursor: pointer; margin-top: -12px" @click="expandViewer(false)" title="Ridimensiona">
              <span class="text" style="font-weight: 700 !important"><v-icon>fullscreen_exit</v-icon> Ridimensiona</span>
            </span>
          </div>
        </div>
        <span
          v-if="showTenPercent"
          class="lnk expand"
          style="position: absolute; font-weight: 700 !important; cursor: default !important; text-decoration: none; top: 0px; left: 0; margin: 0 auto; width: 100%; z-index: 9999">
          Visualizzazione limitata al 10% delle pagine del capitolo
        </span>
      </div>
    </div>

    <span
      v-if="specialRole"
      class="lnk blue expand"
      style="cursor: pointer; position: absolute; right: 150px; top: 25px; z-index: 99"
      @click="dialogDownload = true"
      title="Scarica">
      <span class="text" style="font-weight: 700 !important"><v-icon>download</v-icon> Scarica</span>
    </span>

    <span class="lnk blue expand" style="cursor: pointer; position: absolute; right: 50px; top: 25px; z-index: 99" @click="expandViewer(true)" title="Espandi a tutto schermo">
      <span class="text" style="font-weight: 700 !important"><v-icon>fullscreen</v-icon> Espandi</span>
    </span>
    <span
      v-if="showTenPercent"
      class="lnk expand"
      style="position: absolute; font-weight: 700 !important; cursor: default !important; text-decoration: none; top: 45px; left: 0; z-index: 10; margin: 0 auto; width: 100%">
      Visualizzazione limitata al 10% delle pagine del capitolo
    </span>
    <iframe
      width="102%"
      :style="[{ height: !currentStatus ? 'calc(100vh - 190px)' : 'calc(100vh - 45px)' }]"
      :class="[{ 'full-page': settings.expand }]"
      frameborder="0"
      scrolling="no"
      :src="settings.viewerUrl"></iframe>

    <DownloadPdf v-if="dialogDownload" @trigger="triggerVisibility" />
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import NoContentViewVue from '../components/catalogue/NoContentView.vue'
  import DownloadPdf from '../components/DownloadPDF.vue'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      },
      specialRole: {
        type: Boolean,
        default: false
      }
    },
    components: {
      NoContentView: NoContentViewVue,
      DownloadPdf: DownloadPdf
    },
    data: () => ({
      showTenPercent: false,
      currentStatus: false,
      dialogDownload: false
    }),
    computed: {
      ...mapState(['config']),
      ...mapGetters(["downloadRole"])
    },
    mounted() {
      var self = this
      window.onmessage = function (e) {
        if (e.data == 'locked') {
          self.showTenPercent = true
          self.$forceUpdate()
        }
        if (e.data == 'unlocked') {
          self.showTenPercent = false
          self.$forceUpdate()
        }
      }
      this.$gtm.trackEvent({
        event: 'sezione',
        azione: 'open',
        descrizione: 'viewer',
        proprieta: this.$route.query.cod_vol,
        utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
        crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
        stato: ''
      })
    },
    methods: {
      expandViewer(status) {
        this.currentStatus = status
        if (status) {
          document.getElementsByClassName('expandContainer')[0].style.position = 'static'
          document.getElementsByClassName('header__icon-bar__private')[0].style.display = 'none'
          document.getElementsByClassName('header__icon-bar__private')[1].style.display = 'none'
          document.getElementById('gfl-hf-plain').style.display = 'none'
        } else {
          document.getElementsByClassName('expandContainer')[0].style.position = 'relative'
          document.getElementsByClassName('header__icon-bar__private')[0].style.display = 'initial'
          document.getElementsByClassName('header__icon-bar__private')[1].style.display = 'initial'
          document.getElementById('gfl-hf-plain').style.display = 'block'
        }
        this.settings.expand = status
        this.$forceUpdate()
      },
      triggerVisibility(val){
        this.dialogDownload = val
      }
    }
  }
</script>
<style>
  .full-page {
    position: absolute;
    height: 100vh;
    top: -30px;
    z-index: 999999;
    left: 0;
  }
</style>
